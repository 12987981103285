@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@layer components {
  .testi {
    background: linear-gradient(
      90deg,
      #44ff9a -0.55%,
      #44b0ff 22.86%,
      #8b44ff 48.36%,
      #ff6644 73.33%,
      #ebff70 99.34%
    );
  }

  .background {
    background: #de6161; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #2657eb,
      #de6161
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #2657eb,
      #de6161
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}
